<template>
  <el-container>
    <el-aside width="70%" height="100%">
      <div id="viewer">
        <ul>
          <!--<li v-for="(img,index) in invoice.imageList" :key="index" style="display: none">
            <img :src="img.url" height="100px">
          </li>-->
          <li style="display: none">
            <img :src="invoice.invoiceAddress">
          </li>
        </ul>
      </div>
    </el-aside>
    <el-main width="70%" height="100%">
      <div style="height: 90%">
        <el-form ref="invoice" :model="invoice" style="height:100%;overflow-y: auto;" label-width="110px" size="small">
          <el-form-item label="发票类型">
            <el-select v-model="invoice.invType" placeholder="发票类型" style="width: 95%">
              <el-option v-for="invType in invoiceTypeList" :label="invType.value" :value="invType.key" :key="invType.key"></el-option>
            </el-select>
          </el-form-item>
          <template v-if="invoice.invType === '1' || invoice.invType === '4' || invoice.invType === '10'">
            <!-- 增值税发票 -->
            <el-form-item label="发票号码">
              <el-input v-model="invoice.invNumber"></el-input>
            </el-form-item>
            <el-form-item label="发票代码">
              <el-input v-model="invoice.invCode"></el-input>
            </el-form-item>
            <el-form-item label="发票日期">
              <el-date-picker
                  v-model="invoice.invDate"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <!--<el-form-item label="发票类型">
              <el-input v-model="invoice.invType"></el-input>
            </el-form-item>-->
            <el-form-item label="发票金额">
              <el-input v-model="invoice.invAmount"></el-input>
            </el-form-item>
            <el-form-item label="发票税额">
              <el-input v-model="invoice.invTax"></el-input>
            </el-form-item>
            <el-form-item label="发票合计">
              <el-input v-model="invoice.invTotalAmount"></el-input>
            </el-form-item>
            <el-form-item label="公司名称">
              <el-input v-model="invoice.companyName"></el-input>
            </el-form-item>
            <el-form-item label="公司税号">
              <el-input v-model="invoice.companyTaxCode"></el-input>
            </el-form-item>
            <el-form-item label="供应商名称">
              <el-input v-model="invoice.vendorName"></el-input>
            </el-form-item>
            <el-form-item label="供应商税号">
              <el-input v-model="invoice.vendorTaxCode"></el-input>
            </el-form-item>
          </template>
          <template v-else-if="invoice.invType === '10506'">
            <!-- 机票 -->
            <el-form-item label="乘机人姓名">
              <el-input v-model="invoice.companyName"></el-input>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input v-model="invoice.companyTaxCode"></el-input>
            </el-form-item>
            <el-form-item label="电子客票号码">
              <el-input v-model="invoice.invNumber"></el-input>
            </el-form-item>
            <el-form-item label="验证码">
              <el-input v-model="invoice.invCheckCode"></el-input>
            </el-form-item>
            <el-form-item label="填开日期">
              <el-date-picker
                  v-model="invoice.invDate"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="销售单位代号">
              <el-input v-model="invoice.vendorCode"></el-input>
            </el-form-item>
            <el-form-item label="填开单位">
              <el-input v-model="invoice.vendorName"></el-input>
            </el-form-item>
            <el-form-item label="票价">
              <el-input v-model="invoice.invAmount"></el-input>
            </el-form-item>
            <el-form-item label="税费">
              <el-input v-model="invoice.invTax"></el-input>
            </el-form-item>
            <el-form-item label="总额">
              <el-input v-model="invoice.invTotalAmount"></el-input>
            </el-form-item>
          </template>
          <template v-else-if="invoice.invType === '10500'">
            <!-- 出租车发票 -->
            <el-form-item label="发票号码">
              <el-input v-model="invoice.invNumber"></el-input>
            </el-form-item>
            <el-form-item label="发票代码">
              <el-input v-model="invoice.invCode"></el-input>
            </el-form-item>
            <el-form-item label="发票日期">
              <el-date-picker
                  v-model="invoice.invDate"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="上车时间">
              <el-input v-model="invoice.invoiceDetailList[0].timeGetOn"></el-input>
            </el-form-item>
            <el-form-item label="下车时间">
              <el-input v-model="invoice.invoiceDetailList[0].timeGetOff"></el-input>
            </el-form-item>
            <el-form-item label="里程">
              <el-input v-model="invoice.invoiceDetailList[0].mileage"></el-input>
            </el-form-item>
            <el-form-item label="总金额">
              <el-input v-model="invoice.invTotalAmount"></el-input>
            </el-form-item>
            <el-form-item label="发票所在地">
              <el-input v-model="invoice.invoiceDetailList[0].place"></el-input>
            </el-form-item>
            <el-form-item label="省">
              <el-input v-model="invoice.invoiceDetailList[0].province"></el-input>
            </el-form-item>
            <el-form-item label="市">
              <el-input v-model="invoice.invoiceDetailList[0].city"></el-input>
            </el-form-item>
            <el-form-item label="车牌号">
              <el-input v-model="invoice.invoiceDetailList[0].licensePlate"></el-input>
            </el-form-item>
          </template>
          <template v-else-if="invoice.invType === '10503'">
            <!--火车票-->
            <el-form-item label="号码">
              <el-input v-model="invoice.invNumber"></el-input>
            </el-form-item>
            <el-form-item label="乘车日期">
              <el-date-picker
                  v-model="invoice.invDate"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="乘车时间">
              <el-input v-model="invoice.invoiceDetailList[0].time"></el-input>
            </el-form-item>
            <el-form-item label="乘车人">
              <el-input v-model="invoice.companyName"></el-input>
            </el-form-item>
            <el-form-item label="上车车站">
              <el-input v-model="invoice.invoiceDetailList[0].stationGetOn"></el-input>
            </el-form-item>
            <el-form-item label="下车车站">
              <el-input v-model="invoice.invoiceDetailList[0].stationGetOff"></el-input>
            </el-form-item>
            <el-form-item label="车次">
              <el-input v-model="invoice.invoiceDetailList[0].trainNumber"></el-input>
            </el-form-item>
            <el-form-item label="座位类型">
              <el-input v-model="invoice.invoiceDetailList[0].seat"></el-input>
            </el-form-item>
            <el-form-item label="金额">
              <el-input v-model="invoice.invTotalAmount"></el-input>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input v-model="invoice.companyTaxCode"></el-input>
            </el-form-item>
          </template>
          <template v-else-if="invoice.invType === '10507'">
            <!-- 过路费发票-->
            <el-form-item label="发票号码">
              <el-input v-model="invoice.invNumber"></el-input>
            </el-form-item>
            <el-form-item label="发票代码">
              <el-input v-model="invoice.invCode"></el-input>
            </el-form-item>
            <el-form-item label="发票日期">
              <el-date-picker
                  v-model="invoice.invDate"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="时间">
              <el-input v-model="invoice.invoiceDetailList[0].time"></el-input>
            </el-form-item>
            <el-form-item label="入口">
              <el-input v-model="invoice.invoiceDetailList[0].entrance"></el-input>
            </el-form-item>
            <el-form-item label="出口">
              <el-input v-model="invoice.invoiceDetailList[0].wayOut"></el-input>
            </el-form-item>
            <el-form-item label="总金额">
              <el-input v-model="invoice.invoiceDetailList[0].invTotalAmount"></el-input>
            </el-form-item>
            <el-form-item label="高速标志">
              <el-input v-model="invoice.invoiceDetailList[0].highwayFlag"></el-input>
            </el-form-item>
          </template>
          <template v-else>
            不支持的发票类型
          </template>
          <!--<el-form-item>
            <el-button type="primary" @click="onSubmit">立即创建</el-button>
            <el-button>取消</el-button>
          </el-form-item>-->
        </el-form>
      </div>
      <div style="height: 10%;margin-top:5px">
        <div >
          <el-button type="primary" size="small" @click="onSubmit">立即创建</el-button>
          <el-button size="small" @click="dialog.status=false">取消</el-button>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import request from '@/utils/request'
import ViewerJS from 'viewerjs'
import 'viewerjs/dist/viewer.css'

export default {
  props: {
    id: Number,
    findByPage: Function,
    dialog: Object,
    invoiceTypeList: Array
  },
  data() {
    return {
      invoice: {
        invNumber: '',
        invCode: '',
        invDate: '',
        invType: '',
        invAmount: '',
        invTax: '',
        invTotalAmount: '',
        invoiceAddress: '',
        companyName: '',
        companyTaxCode: '',
        vendorName: '',
        vendorTaxCode: '',
        imageList: [],
        invoiceDetailList: []
      },
    }
  },
  mounted() {
    return request({
      url: `/invoice/edocInvoice/findById/` + this.id,
      method: 'post',
      contentType: 'application/json',
      baseURL: 'http://127.0.0.1:8092'
    }).then(result => {
          return request({
            url: `/invoice/edocImage/findById/` + result.data.edocImageId,
            method: 'post',
            contentType: 'application/json',
            baseURL: 'http://127.0.0.1:8092'
          }).then(result1 => {

            console.log(result1.data)
            this.invoice = result.data
            this.invoice.invoiceAddress = result1.data.preViewUrl

            this.$nextTick(() => {
              this.initViewer()
            })
          })


    })
  },
  methods: {
    onSubmit() {
      console.log(this.invoiceTypeList);
    },
    initViewer() {
      const viewer = document.getElementById("viewer")
      new ViewerJS(viewer, {
        inline: true,
        navbar: true,
        button: false,
        title: true
      })
    }
  }
}
</script>

<style scoped>
.el-date-editor.el-input {
  width: 95%;
}

.el-input {
  height: 32px;
  line-height: 32px;
  width: 95% !important;
}

.el-aside {
  color: #333;
  text-align: center;
}

.el-main {
  color: #333;
  text-align: center;
  padding: 0 !important;
  overflow: hidden;
}

.el-container {
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.el-form::-webkit-scrollbar {
  width: 3px;
  height: 1px;
}
.el-form::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: #535353;
}
.el-form::-webkit-scrollbar-track {/*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 10px;
  background: #EDEDED;
}

#viewer {
  width: 90%;
  height: 90%;
}

ul {
  display: flex;
  flex-wrap: wrap;
}

ul li {
  list-style: none;
  /*border: 2px solid #CCC;*/
  border-radius: 3px;
  padding: 1px;
  margin: 10px;
  cursor: pointer;
}
</style>
